import React from "react";
import { Link } from "gatsby";

export default () => (
  <nav role="navigation" className="bg-theme2-bg-1 p-4">
    <div className="flex flex-col md:flex-row md:justify-between md:max-w-5xl md:mx-auto">
      <div className="font-semibold font-serif text-theme2-accent text-4xl">
        <Link to="/">Ruiz & Knott</Link>
      </div>
      <div className="flex">
        <div className="font-bold font-sans p-2 pl-0 text-lightCool text-xs tracking-wider uppercase">
          <Link
            to="/projects"
            activeClassName="border-b-2 border-theme2-accent"
            partiallyActive={true}
          >
            Projects
          </Link>
        </div>
        {/* <div className="font-bold font-sans p-2 text-lightCool text-xs tracking-wider uppercase">
          <Link
            to="/events"
            activeClassName="border-b-2 border-theme2-accent"
            partiallyActive={true}
          >
            Events
          </Link>
        </div> */}
        <div className="font-bold font-sans p-2 text-lightCool text-xs tracking-wider uppercase">
          <Link to="/about" activeClassName="border-b-2 border-theme2-accent">
            About
          </Link>
        </div>
        <div className="font-bold font-sans p-2 text-lightCool text-xs tracking-wider uppercase">
          <Link
            to="/year-ten"
            activeClassName="border-b-2 border-theme2-accent"
          >
            Year 10
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

// // Use these icons later
// <img src={require("../../static/assets/settings.png")} alt="settings icon" />
// <img src={require("../../static/assets/search.png")} alt="search icon" />
